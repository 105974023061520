import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Facebook({ size, color, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M13.399 20.9969V12.8009H16.164L16.575 9.59193H13.399V7.54792C13.399 6.62192 13.657 5.98793 14.986 5.98793H16.67V3.12693C15.8506 3.03912 15.027 2.99672 14.203 2.99993C11.759 2.99993 10.081 4.49193 10.081 7.23093V9.58593H7.33398V12.7949H10.087V20.9969H13.399Z"
        fill={color}
      />
    </BaseSvgStyle>
  );
}

export default Facebook;
